import React from "react"
import { useMediaQuery } from "@react-hook/media-query"
import { Link } from "gatsby"
import {
  Chain,
  TopMapPinLime,
  CheckBox,
  Label,
  Truck,
  Sorting,
  SteeringWheel,
  MapPinGear,
  Binocular,
  Doorstep,
  Capture,
  Alert,
  TimelineCircle,
  FirstMile,
  Sortation,
  LastMile,
  ServicesFirstMiddleFinal,
  ServicesFlow,
  ServiceFlowDesktop,
  ServiceFlowMobile,
  CrossCountryMapDark,
  BoxDoorPortalLight,
  TechEnabled,
  Gauge,
  PricingTagGroup,
  BoxFast,
  AllSizes,
  GreenApproach,
  HorizontalLinePinLime,
  ReduceCosts,
  CustomerExp,
  ScaleBiz,
  Retail,
  ThreePL,
  AlertBell,
  SnapDesktop,
  HappyDesktop,
  EasyDesktop,
  ObjectsPortalLight,
} from "../components/svg"
import {
  Seo,
  Layout,
  Section,
  Grid,
  Container,
  Col,
  FlipCards,
} from "../components"

function Services() {
  const timeline = [
    {
      icon: CheckBox,
      label: "Shipper Engages ShipX",
    },
    {
      icon: Label,
      label: "Shipper Labels Packages For Pickup",
    },
    {
      icon: Truck,
      label: "ShipX Pick Up / Receive Packages",
    },
    {
      icon: Sorting,
      label: "Packages Arrives At Sorting Facility",
    },
    {
      icon: SteeringWheel,
      label: "Routing Optimized",
    },
    {
      icon: MapPinGear,
      label: "Carrier Identified",
    },
    {
      icon: Binocular,
      label: "Chain Of Custody Continuously Monitored",
    },
    {
      icon: Doorstep,
      label: "Package Delivery To Consignee",
    },
    {
      icon: Capture,
      label: "Driver Captures Proof Of Delivery",
    },
    {
      icon: Alert,
      label: "Automated Notification For Delivery Completion",
    },
  ]

  const steps = [
    {
      icon: FirstMile,
      caption: "We’ll pick up or you deliver to us. Your choice.",
    },
    {
      icon: Sortation,
      caption: "Parcels are sorted, consolidated and taken for delivery.",
    },
    {
      icon: LastMile,
      caption: "Parcels are delivered to your consignees.",
    },
  ]

  const cards = [
    {
      id: 1,
      title: "Eco friendly delivery",
      graphic: GreenApproach,
      frontContent: "Eco friendly delivery",
      backContent:
        "ShipX closely collaborates with our Vetted Delivery Network™ (VDN) of carriers to develop novel approaches of incorporating battery-electric vehicles into their fleets, with the goal of distributing the resulting environmental benefits throughout our shipper community.",
    },
    {
      id: 2,
      title: "A tech-enabled, redundant network",
      graphic: TechEnabled,
      frontContent: "A tech-enabled, redundant network",
      backContent:
        "New technologies and a robust Vetted Delivery Network™ (VDN) allow us to build dynamic routing that has built-in redundancy, thus ensuring continuity on every shipment.",
    },
    {
      id: 3,
      title: "Simple, predictable pricing",
      graphic: PricingTagGroup,
      frontContent: "Simple, predictable pricing",
      backContent:
        "We take complexity out of the final mile pricing equation. Our rate sheets are clear and straightforward, making it easy to plan and price your products.",
    },
    {
      id: 4,
      title: "Door-to-door tracking",
      graphic: BoxDoorPortalLight,
      frontContent: "Door-to-door tracking",
      backContent:
        "By scanning every package at every milestone, we provide full visibility across the entire parcel journey and provide real-time tracking data on our site or yours.",
    },
    {
      id: 5,
      title: "The capacity you need right now",
      graphic: Gauge,
      frontContent: "The capacity you need right now",
      backContent:
        "Our mission is to provide an efficient, reliable, scalable alternative to the handful of national delivery carriers that dominate — and restrict — the shipping marketplace.",
    },
    {
      id: 6,
      title: "A flexible partner",
      graphic: ObjectsPortalLight,
      frontContent: "A flexible partner",
      backContent:
        "ShipX gives shippers never-before-seen options in domestic ground delivery. We’ll deploy scalable delivery solutions based on the size, speed and volume of your shipping output.",
    },
    {
      id: 7,
      title: "Up and running — fast",
      graphic: BoxFast,
      frontContent: "Up and running — fast",

      backContent:
        "In most cases, we can integrate your packages into our network within a period of three days.",
    },
    {
      id: 8,
      title: "Handling packages of all sizes and shapes",
      graphic: AllSizes,
      frontContent: "Handling packages of all sizes and shapes",
      backContent:
        "We simply deliver. Provide us with your unique or oversized items. We will ensure their prompt and efficient delivery.",
    },
  ]

  const isDesktop = useMediaQuery("only screen and (min-width: 1024px)") // adjust the breakpoint as needed

  return (
    <Layout>
      <Seo
        title="See How Our Shipping Technology Platform Sets ShipX Apart"
        description="Performance you expect. Service you deserve. ShipX leverages its Vetted Carrier Network (VCN) to form unified, seamless, final mile delivery solutions."
        slug="services"
      />

      {/* hero section */}
      <Section padding="none">
        <Container
          padding="none"
          bgColor="bg-gradient-to-l from-gray-50/75 via-gray-50/75 to-gray-50/75"
        >
          <div className="space-y-8 py-4">
            <h1 className="text-center pt-12">
              Middle & Last Mile Shipping Made Easy
            </h1>
            <Container
              padding="none"
              xInitial={"-100%"}
              delay={0.2}
              classes="w-screen h-auto"
            >
              <Chain />
            </Container>
          </div>
        </Container>
      </Section>

      {/* <Section padding="none">
        <Container
          padding="none"
          classes="bg-gradient-to-r from-gray-900/95 via-gray-700/95 to-gray-900/95 space-y-16 px-4 py-24 lg:py-24 lg:px-0"
        >
          <div className="space-y-6 max-w-2xl mx-auto text-white text-center">
            <h2 className="text-accent-light text-2xl lg:text-4xl text-center font-semibold mx-auto">
              How can ShipX help you?
            </h2>
            <p className="text-lg lg:text-xl">
              ShipX is a tech-enabled delivery service provider that helps
              enables wholesale, e-commerce, retail and 3PL shippers to optimize
              and execute deliveries seamlessly, from middle to final mile.
            </p>
          </div>
          <div className="space-y-8">
            <h2 className="text-accent-light text-xl lg:text-3xl text-center font-semibold mx-auto">
              Serving:
            </h2>
            <Grid classes="lg:grid-cols-2 max-w-2xl mx-auto text-white text-lg lg:text-xl space-y-4 lg:space-y-0 px-4 lg:px-0">
              <Col>
                <ul className="list-none space-y-4 lg:space-y-6">
                  <li className="flex">
                    <i className="fas fa-check text-accent mr-3" />
                    Manufacturing / Wholesale
                  </li>
                  <li className="flex">
                    <i className="fas fa-check text-accent mr-3" />
                    E-Commerce brands
                  </li>
                  <li className="flex">
                    <i className="fas fa-check text-accent mr-3" />
                    3PL & Fulfillment centers
                  </li>
                </ul>
              </Col>
              <Col>
                <ul className="list-none space-y-3 lg:space-y-6">
                  <li className="flex">
                    <i className="fas fa-check text-accent mr-3" />
                    Regional and major carriers
                  </li>
                  <li className="flex">
                    <i className="fas fa-check text-accent mr-3" />
                    Retailers
                  </li>
                  <li className="flex">
                    <i className="fas fa-check text-accent mr-3" />
                    Private fleets
                  </li>
                </ul>
              </Col>
            </Grid>
          </div>
        </Container>
      </Section> */}

      <Section padding="none" classes="cta-bg">
        <Container padding="global-md" classes="text-center py-24 space-y-12">
          <h2 className="text-accent-light text-center mx-auto p">
            Turnkey Delivery Solutions
          </h2>
          <Grid classes="lg:grid-cols-2 gap-8 space-y-8 lg:space-y-0">
            <Col>
              <div className="mx-auto space-y-6">
                <ul className="text-left w-fit flex flex-col justify-center mx-auto space-y-4 lg:space-y-2 list-indent">
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Parcel Delivery to 45 States and 18,000 Zip Codes
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Middle Mile Services Available in 48 States and Canada
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    100+ Years of Combined Experience
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Redundant and Reliable Parcel Delivery Network
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Scalability to Enable Growth
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Simple, Predictable Pricing
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Effortless Onboarding Experience
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Middle and Final Mile Services Available
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Integrations with Leading TMS (Transport Management System)
                    Platforms
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Comprehensive Parcel Tracking and Monitoring Capabilities
                  </li>
                </ul>
              </div>
            </Col>
            <Col>
              <div className="space-y-6">
                <p className="text-center text-white text-xl lg:text-3xl font-light">
                  Discover how our cutting-edge technology and cost efficient
                  solutions can improve your shipping experience.
                </p>
                <div className="pt-8 mx-auto flex justify-center">
                  <Link
                    to="/contact/"
                    className="px-10 py-6 text-secondary font-bold lg:text-2xl sm:text-2xl rounded-full bg-accent-light hover:bg-accent-dark hover:text-white transition duration-150 ease-in-out"
                  >
                    Schedule a meeting
                  </Link>
                </div>
              </div>
            </Col>
          </Grid>
        </Container>
      </Section>

      {/* benefits and features section */}
      <div className="space-y-12 py-24">
        <Section padding="none" classes="px-4 lg:px-0">
          <Container padding="global-md" classes="bg-white">
            <FlipCards cards={cards} />
            {/* <div className="space-y-8">
              <div className="space-y-2">
                <h2 className="text-2xl text-secondary-dark font-semibold mx-auto">
                  Eco friendly delivery.
                </h2>
                <p>
                  ShipX will collaborate closely with our Vetted Delivery
                  Network™ (VDN) of carriers to develop novel approaches of
                  incorporating battery-electric vehicles into their fleets,
                  with the goal of distributing the resulting environmental
                  benefits throughout our shipper community.
                </p>
              </div>
              <div className="space-y-2">
                <h2 className="text-2xl text-secondary-dark font-semibold mx-auto">
                  A tech-enabled, redundant network.
                </h2>
                <p>
                  New technologies and a robust Vetted Delivery Network™ (VDN)
                  allow us to build dynamic routing that has built-in
                  redundancy, thus ensuring continuity on every shipment.
                </p>
              </div>
              <div className="space-y-2">
                <h2 className="text-2xl text-secondary-dark font-semibold mx-auto">
                  Simple, predictable pricing.
                </h2>
                <p>
                  We take complexity out of the final mile pricing equation. Our
                  rate sheets are clear and straightforward, making it easy to
                  plan and price your products.
                </p>
              </div>
              <div className="space-y-2">
                <h2 className="text-2xl text-secondary-dark font-semibold mx-auto">
                  Door-to-door tracking.
                </h2>
                <p>
                  By scanning every package at every milestone, we provide full
                  visibility across the entire parcel journey and provide
                  real-time tracking data on our site or yours.
                </p>
              </div>
              <div className="space-y-2">
                <h2 className="text-2xl text-secondary-dark font-semibold mx-auto">
                  The capacity you need right now.
                </h2>
                <p>
                  Our mission is to provide an efficient, reliable, scalable
                  alternative to the handful of national delivery carriers that
                  dominate — and restrict — the shipping marketplace.
                </p>
              </div>
              <div className="space-y-2">
                <h2 className="text-2xl text-secondary-dark font-semibold mx-auto">
                  A flexible partner.
                </h2>
                <p>
                  ShipX gives shippers never-before-seen options in domestic
                  ground delivery. We’ll deploy scalable delivery solutions
                  based on the size, speed and volume of your shipping output.
                </p>
              </div>
            </div> */}
          </Container>
        </Section>
        {/* how it works section */}
        <Section padding="none" classes="px-4 lg:px-0">
          <Container
            padding="global-md"
            classes="bg-white p-8 py-20 space-y-12"
          >
            {/* <h2 className="text-2xl text-secondary-dark text-center font-semibold mx-auto">
              Efficiency, Every Step of the Way
            </h2> */}
            <h2 className="text-2xl text-secondary-dark text-center font-semibold mx-auto">
              One Call, One Complete Solution
            </h2>
            <div className="flex justify-center">
              {isDesktop ? <ServiceFlowDesktop /> : <ServiceFlowMobile />}
            </div>
          </Container>
        </Section>
        {/* complete solution section */}
        {/* <Section padding="none" classes="px-4 lg:px-0">
          <Container
            padding="global-md"
            classes="bg-gradient-to-r from-gray-900/90 via-gray-800/95 to-gray-900/90 px-6 py-12 lg:p-12 space-y-8 text-white"
          >
            <div className="space-y-3">
              <h2 className="text-2xl text-accent-light font-semibold mx-auto">
                One Call, One Complete Solution
              </h2>
              <p>
                ShipX leverages its full complement of TL and LTL carrier bases,
                along with its parcel Vetted Delivery Network™ (VDN) to form
                unified, turnkey delivery solutions no matter your shipping
                needs. These networks have been carefully screened according to
                their volume, efficiency and use of technology. In turn, this
                enables ShipX to offer a reliable and high level of service and
                turn-key solutions for shippers of a wide range of products.
              </p>
            </div>
            <div className="max-w-2xl mx-auto">
              <Grid classes="lg:grid-cols-2 space-y-6 lg:space-y-0">
                <Col>
                  <ul className="list-none space-y-6">
                    <li className="flex">
                      <i className="fas fa-check text-accent mr-3" />
                      Middle mile truckload and contract capacity services
                    </li>
                    <li className="flex">
                      <i className="fas fa-check text-accent mr-3" />
                      Direct-to-store distribution
                    </li>
                    <li className="flex">
                      <i className="fas fa-check text-accent mr-3" />
                      Direct carrier, air cargo injection
                    </li>
                    <li className="flex">
                      <i className="fas fa-check text-accent mr-3" />
                      Line-haul injection
                    </li>
                    <li className="flex">
                      <i className="fas fa-check text-accent mr-3" />
                      Local sortation and transfer
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul className="list-none space-y-6">
                    <li className="flex">
                      <i className="fas fa-check text-accent mr-3" />
                      Warehouse to warehouse transfers
                    </li>
                    <li className="flex">
                      <i className="fas fa-check text-accent mr-3" />
                      Coast-to-coast zone skipping
                    </li>
                    <li className="flex">
                      <i className="fas fa-check text-accent mr-3" />
                      Power-only tractors
                    </li>
                    <li className="flex">
                      <i className="fas fa-check text-accent mr-3" />
                      26-foot box trucks, sprinter vans
                    </li>
                    <li className="flex">
                      <i className="fas fa-check text-accent mr-3" />
                      Peak capacity coverage
                    </li>
                  </ul>
                </Col>
              </Grid>
            </div>
          </Container>
        </Section> */}
        {/* cta section */}
        <Section padding="none" classes="px-4 lg:px-0">
          <Container
            padding="global-md"
            classes="bg-accent-dark px-6 py-20 space-y-12 mx-auto text-center"
          >
            <p className="text-white font-bold mx-auto text-2xl sm:text-3xl lg:text-4xl max-w-lg">
              Start your parcel delivery journey with ShipX today.
            </p>
            <div className="lg:pt-8">
              <Link
                to="/contact/"
                className="px-10 py-3 text-secondary-light font-bold sm:text-xl lg:text-2xl rounded-full bg-accent-light hover:bg-secondary-light hover:text-white transition duration-150 ease-in-out"
              >
                Schedule a meeting
              </Link>
            </div>
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export default Services
